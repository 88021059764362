<template>
  <div>
    <template v-if="!stats">
      Carregando...
    </template>

    <template v-else>
      <h3 class="mb-4">Insígnias</h3>
      <div class="row" style="">
        <div v-for="(badge, i) in badges" :key="i" class="col-lg-3">
          <div
            class="card rounded-lg shadow"
            style="background-color: rgba(0, 132, 255, 0.75);"
          >
            <div class="card-body badge-padding">
              <img
                :src="badge.image"
                class="img-fluid mx-auto d-block"
                :alt="badge.title"
                width="100"
                v-b-tooltip.hover.bottom="badge.title"
              />

              <!--h5 class="mt-2 text-dark text-center">{{ badge.title }}</!--h5-->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import defaultImage from "@/assets/chevron-11.svg";
import correctQuestionsBadge1 from "@/assets/badges/correctQuestionsBadge1.svg";
import correctQuestionsBadge50 from "@/assets/badges/correctQuestionsBadge50.svg";
import correctQuestionsBadge100 from "@/assets/badges/correctQuestionsBadge100.svg";
import correctQuestionsBadge200 from "@/assets/badges/correctQuestionsBadge200.svg";
import correctQuestionsBadge500 from "@/assets/badges/correctQuestionsBadge500.svg";
import correctQuestionsBadge1000 from "@/assets/badges/correctQuestionsBadge1000.svg";
import correctQuestionsBadge2000 from "@/assets/badges/correctQuestionsBadge2000.svg";
import correctQuestionsBadge5000 from "@/assets/badges/correctQuestionsBadge5000.svg";

import bestDayStreakBadge30 from "@/assets/badges/bestDayStreakBadge30.svg";
import bestDayStreakBadge50 from "@/assets/badges/bestDayStreakBadge50.svg";
import bestDayStreakBadge100 from "@/assets/badges/bestDayStreakBadge100.svg";
import bestDayStreakBadge200 from "@/assets/badges/bestDayStreakBadge200.svg";
import bestDayStreakBadge500 from "@/assets/badges/bestDayStreakBadge500.svg";

import bestDailyCorrectAnswers20 from "@/assets/badges/bestDailyCorrectAnswer20.svg";
import bestDailyCorrectAnswers50 from "@/assets/badges/bestDailyCorrectAnswer50.svg";
import bestDailyCorrectAnswers75 from "@/assets/badges/bestDailyCorrectAnswer75.svg";
import bestDailyCorrectAnswers100 from "@/assets/badges/bestDailyCorrectAnswer100.svg";
import bestDailyCorrectAnswers150 from "@/assets/badges/bestDailyCorrectAnswer150.svg";
import bestDailyCorrectAnswers200 from "@/assets/badges/bestDailyCorrectAnswer200.svg";
import bestDailyCorrectAnswers250 from "@/assets/badges/bestDailyCorrectAnswer250.svg";
import bestDailyCorrectAnswers300 from "@/assets/badges/bestDailyCorrectAnswer300.svg";

import bestCorrectAnswerStreak3 from "@/assets/badges/bestCorrectAnswerStreak3.svg";
import bestCorrectAnswerStreak5 from "@/assets/badges/bestCorrectAnswerStreak5.svg";
import bestCorrectAnswerStreak10 from "@/assets/badges/bestCorrectAnswerStreak10.svg";
import bestCorrectAnswerStreak15 from "@/assets/badges/bestCorrectAnswerStreak15.svg";
import bestCorrectAnswerStreak20 from "@/assets/badges/bestCorrectAnswerStreak20.svg";
import bestCorrectAnswerStreak25 from "@/assets/badges/bestCorrectAnswerStreak25.svg";
import bestCorrectAnswerStreak30 from "@/assets/badges/bestCorrectAnswerStreak30.svg";
import bestCorrectAnswerStreak35 from "@/assets/badges/bestCorrectAnswerStreak35.svg";

import bestCorrectionRateInSession80 from "@/assets/badges/bestCorrectionRateInSession80.svg";
import bestCorrectionRateInSession86 from "@/assets/badges/bestCorrectionRateInSession86.svg";
import bestCorrectionRateInSession92 from "@/assets/badges/bestCorrectionRateInSession92.svg";

import answerInHolidays10 from "@/assets/badges/answersInHolidays10.svg";
import answerInHolidays30 from "@/assets/badges/answersInHolidays30.svg";
import answerInHolidays50 from "@/assets/badges/answersInHolidays50.svg";
import answerInHolidays100 from "@/assets/badges/answersInHolidays100.svg";
import answerInHolidays200 from "@/assets/badges/answersInHolidays200.svg";
import answerInHolidays300 from "@/assets/badges/answersInHolidays300.svg";

import activeDays3 from "@/assets/badges/activeDays3.svg";
import activeDays7 from "@/assets/badges/activeDays7.svg";
import activeDays14 from "@/assets/badges/activeDays14.svg";
import activeDays21 from "@/assets/badges/activeDays21.svg";
import activeDays30 from "@/assets/badges/activeDays30.svg";
import activeDays40 from "@/assets/badges/activeDays40.svg";
import activeDays50 from "@/assets/badges/activeDays50.svg";
import activeDays100 from "@/assets/badges/activeDays100.svg";

import reviewsWithText1 from "@/assets/badges/reviewsText1.svg";
import reviewsWithText3 from "@/assets/badges/reviewsText3.svg";
import reviewsWithText5 from "@/assets/badges/reviewsText5.svg";
import reviewsWithText10 from "@/assets/badges/reviewsText10.svg";
import reviewsWithText15 from "@/assets/badges/reviewsText15.svg";
import reviewsWithText20 from "@/assets/badges/reviewsText20.svg";
import reviewsWithText25 from "@/assets/badges/reviewsText25.svg";
import reviewsWithText30 from "@/assets/badges/reviewsText30.svg";

import reviewsWithAudio1 from "@/assets/badges/reviewsAudio1.svg";
import reviewsWithAudio3 from "@/assets/badges/reviewsAudio3.svg";
import reviewsWithAudio5 from "@/assets/badges/reviewsAudio5.svg";
import reviewsWithAudio10 from "@/assets/badges/reviewsAudio10.svg";
import reviewsWithAudio15 from "@/assets/badges/reviewsAudio15.svg";
import reviewsWithAudio20 from "@/assets/badges/reviewsAudio20.svg";
import reviewsWithAudio25 from "@/assets/badges/reviewsAudio25.svg";
import reviewsWithAudio30 from "@/assets/badges/reviewsAudio30.svg";

import reviewsWithVideo1 from "@/assets/badges/reviewsVideo1.svg";
import reviewsWithVideo3 from "@/assets/badges/reviewsVideo3.svg";
import reviewsWithVideo5 from "@/assets/badges/reviewsVideo5.svg";
import reviewsWithVideo10 from "@/assets/badges/reviewsVideo10.svg";
import reviewsWithVideo15 from "@/assets/badges/reviewsVideo15.svg";
import reviewsWithVideo20 from "@/assets/badges/reviewsVideo20.svg";
import reviewsWithVideo25 from "@/assets/badges/reviewsVideo25.svg";
import reviewsWithVideo30 from "@/assets/badges/reviewsVideo30.svg";

import issuesSolved1 from "@/assets/badges/issuesSolved1.svg";
import issuesSolved3 from "@/assets/badges/issuesSolved3.svg";
import issuesSolved5 from "@/assets/badges/issuesSolved5.svg";
import issuesSolved10 from "@/assets/badges/issuesSolved10.svg";
import issuesSolved15 from "@/assets/badges/issuesSolved15.svg";
import issuesSolved20 from "@/assets/badges/issuesSolved20.svg";
import issuesSolved25 from "@/assets/badges/issuesSolved25.svg";
import issuesSolved30 from "@/assets/badges/issuesSolved30.svg";

import reactionInReviews1 from "@/assets/badges/reactionsInReviews1.svg";
import reactionInReviews5 from "@/assets/badges/reactionsInReviews5.svg";
import reactionInReviews10 from "@/assets/badges/reactionsInReviews10.svg";
import reactionInReviews25 from "@/assets/badges/reactionsInReviews25.svg";
import reactionInReviews50 from "@/assets/badges/reactionsInReviews50.svg";
import reactionInReviews100 from "@/assets/badges/reactionsInReviews100.svg";
import reactionInReviews200 from "@/assets/badges/reactionsInReviews200.svg";
import reactionInReviews300 from "@/assets/badges/reactionsInReviews300.svg";

import friendsInvited1 from "@/assets/badges/friendsInvited1.svg";
import friendsInvited5 from "@/assets/badges/friendsInvited5.svg";
import friendsInvited10 from "@/assets/badges/friendsInvited10.svg";
import friendsInvited25 from "@/assets/badges/friendsInvited25.svg";
import friendsInvited50 from "@/assets/badges/friendsInvited50.svg";
import friendsInvited100 from "@/assets/badges/friendsInvited100.svg";
import friendsInvited200 from "@/assets/badges/friendsInvited200.svg";
import friendsInvited300 from "@/assets/badges/friendsInvited300.svg";

import subscribedDays2M from "@/assets/badges/subscribedBadge2M.svg";
import subscribedDays3M from "@/assets/badges/subscribedBadge3M.svg";
import subscribedDays6M from "@/assets/badges/subscribedBadge6M.svg";
import subscribedDays1Y from "@/assets/badges/subscribedBadge1Y.svg";
import subscribedDays2Y from "@/assets/badges/subscribedBadge2Y.svg";
import subscribedDays3Y from "@/assets/badges/subscribedBadge3Y.svg";

const images = {
  correctAnswers: [
    correctQuestionsBadge1,
    correctQuestionsBadge50,
    correctQuestionsBadge100,
    correctQuestionsBadge200,
    correctQuestionsBadge500,
    correctQuestionsBadge1000,
    correctQuestionsBadge2000,
    correctQuestionsBadge5000,
  ],
  bestDailyAnswers: [
    bestDayStreakBadge30,
    bestDayStreakBadge50,
    bestDayStreakBadge100,
    bestDayStreakBadge200,
    bestDayStreakBadge500,
  ],
  bestDailyCorrectAnswers: [
    bestDailyCorrectAnswers20,
    bestDailyCorrectAnswers50,
    bestDailyCorrectAnswers75,
    bestDailyCorrectAnswers100,
    bestDailyCorrectAnswers150,
    bestDailyCorrectAnswers200,
    bestDailyCorrectAnswers250,
    bestDailyCorrectAnswers300,
  ],
  bestCorrectAnswerStreak: [
    bestCorrectAnswerStreak3,
    bestCorrectAnswerStreak5,
    bestCorrectAnswerStreak10,
    bestCorrectAnswerStreak15,
    bestCorrectAnswerStreak20,
    bestCorrectAnswerStreak25,
    bestCorrectAnswerStreak30,
    bestCorrectAnswerStreak35,
  ],
  bestCorrectionRateInSession: [
    bestCorrectionRateInSession80,
    bestCorrectionRateInSession86,
    bestCorrectionRateInSession92,
  ],
  answersInHolydays: [
    answerInHolidays10,
    answerInHolidays30,
    answerInHolidays50,
    answerInHolidays100,
    answerInHolidays200,
    answerInHolidays300,
  ],
  activeDays: [
    activeDays3,
    activeDays7,
    activeDays14,
    activeDays21,
    activeDays30,
    activeDays40,
    activeDays50,
    activeDays100,
  ],
  reviewsWithText: [
    reviewsWithText1,
    reviewsWithText3,
    reviewsWithText5,
    reviewsWithText10,
    reviewsWithText15,
    reviewsWithText20,
    reviewsWithText25,
    reviewsWithText30,
  ],
  reviewsWithAudio: [
    reviewsWithAudio1,
    reviewsWithAudio3,
    reviewsWithAudio5,
    reviewsWithAudio10,
    reviewsWithAudio15,
    reviewsWithAudio20,
    reviewsWithAudio25,
    reviewsWithAudio30,
  ],
  reviewsWithVideo: [
    reviewsWithVideo1,
    reviewsWithVideo3,
    reviewsWithVideo5,
    reviewsWithVideo10,
    reviewsWithVideo15,
    reviewsWithVideo20,
    reviewsWithVideo25,
    reviewsWithVideo30,
  ],
  issuesSolved: [
    issuesSolved1,
    issuesSolved3,
    issuesSolved5,
    issuesSolved10,
    issuesSolved15,
    issuesSolved20,
    issuesSolved25,
    issuesSolved30,
  ],

  reactionInReviews: [
    reactionInReviews1,
    reactionInReviews5,
    reactionInReviews10,
    reactionInReviews25,
    reactionInReviews50,
    reactionInReviews100,
    reactionInReviews200,
    reactionInReviews300,
  ],
  friendsInvited: [
    friendsInvited1,
    friendsInvited5,
    friendsInvited10,
    friendsInvited25,
    friendsInvited50,
    friendsInvited100,
    friendsInvited200,
    friendsInvited300,
  ],

  subscribedDays: [
    subscribedDays2M,
    subscribedDays3M,
    subscribedDays6M,
    subscribedDays1Y,
    subscribedDays2Y,
    subscribedDays3Y,
  ],
};

const badgePointsByColumn = {
  correctAnswers: {
    title: "$ª Questão Correta",
    values: [3000, 2000, 1000, 500, 200, 100, 50, 1],
  },
  bestDailyAnswers: {
    title: "$ Questões Resolvidas no Dia",
    values: [300, 200, 100, 50, 30],
  },
  bestDailyCorrectAnswers: {
    title: "$ Questões Certas no Dia",
    values: [300, 250, 200, 150, 100, 75, 50, 20],
  },
  bestCorrectAnswerStreak: {
    title: "$ Questões Corretas Ininterruptamente",
    values: [35, 30, 25, 20, 15, 10, 5, 3],
  },
  bestCorrectionRateInSession: {
    title: "Taxa de Acerto Superior a $% em uma Sessão",
    values: [92, 86, 80],
  },
  answersInHolydays: {
    title: "$ Questões Resolvidas no Feriado",
    values: [300, 200, 100, 50, 30, 10],
  },
  activeDays: {
    title: "Acesso Diário por $ Dias",
    values: [100, 50, 40, 30, 21, 14, 7, 3],
  },
  reviewsWithText: {
    title: "$ Questões Revisadas Por Escrito",
    values: [30, 25, 20, 15, 10, 5, 3, 1],
  },
  reviewsWithAudio: {
    title: "$ Questões Revisadas Por Áudio",
    values: [30, 25, 20, 15, 10, 5, 3, 1],
  },
  reviewsWithVideo: {
    title: "$ Questões Revisadas por Vídeo",
    values: [30, 25, 20, 15, 10, 5, 3, 1],
  },
  issuesSolved: {
    title: "$ª Ajuda Reportada Positivamente",
    values: [30, 25, 20, 15, 10, 5, 3, 1],
  },
  reactionInReviews: {
    title: "$ª Curtida em Revisões",
    values: [300, 200, 150, 100, 50, 25, 10, 1],
  },
  friendsInvited: {
    title: "$ª Amigo Assinou",
    values: [300, 200, 100, 50, 25, 10, 5, 1],
  },

  subscribedDays: {
    title: "Dias assinante",
    values: [60, 90, 180, 365, 365 * 2, 365 * 3],
  },
};

export default {
  name: "UserBadges",

  props: ["user", "stats"],

  computed: {
    badges() {
      const { stats } = this;
      if (!stats) {
        return [];
      }

      return Object.entries(badgePointsByColumn)
        .map(([col, { title, values }]) => {
          values = values.sort((a, b) => a - b);

          const badge = this.user.badges[col];
          if (!badge) {
            return;
          }

          return {
            title: title.replace("$", values[badge - 1]),
            image: images[col] ? images[col][badge - 1] : defaultImage,
          };
        })
        .filter((x) => x);
    },
  },
};
</script>

<style lang="scss">
.badge-padding {
  padding: 0.75rem 0.5rem !important;
}
</style>

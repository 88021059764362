<template>
  <Layout>
    <section v-if="!loading" class="my-3">
      <div class="row">
        <div class="col-lg-4 col-12">
          <UserVerticalCard v-if="user" :user="user" />
        </div>
        <div class="col-lg-8 col-12">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div
                class="card bg-dark"
                :style="!user.participateInRankings ? 'height: 150px' : ''"
              >
                <div class="card-body">
                  <div class="text-center">
                    <h5 class="text-white-50">
                      Ranking global
                    </h5>
                    <h1 class="text-white">
                      {{
                        user.participateInRankings
                          ? stats && stats.rankingPosition
                            ? `${stats.rankingPosition}º`
                            : "?"
                          : ""
                      }}
                    </h1>
                    <h5
                      class="text-white"
                      :class="!user.participateInRankings ? 'mt-5 py-2' : ''"
                    >
                      {{
                        user.participateInRankings
                          ? "Colocado"
                          : "Você desativou"
                      }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="card bg-success">
                <div class="card-body">
                  <div class="text-center">
                    <h5 class="text-white-50">
                      Plano atual
                    </h5>
                    <h1 class="text-white">
                      <i class="mdi mdi-id-card"></i>
                    </h1>
                    <h5 class="text-white">Comum</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="card bg-danger">
                <div class="card-body">
                  <div class="text-center">
                    <h5 class="text-white-50">
                      Análises
                    </h5>
                    <h1 class="text-white">
                      {{ (stats && stats.reviews && stats.reviews.total) || 0 }}
                    </h1>
                    <h5 class="text-white">Aceitas</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="card bg-primary">
                <div class="card-body">
                  <div class="text-center">
                    <h5 class="text-white-50">
                      Professores
                    </h5>
                    <h1 class="text-white">
                      {{ stats && stats.countUserMentors }}
                    </h1>
                    <h5 class="text-white">Ativos</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body p-4">
              <UserBadges :user="user" :stats="stats" />
            </div>
          </div>

          <template v-if="stats.mentorsWithOpenConversations.length > 0">
            <div class="card">
              <div class="card-body p-4">
                <h4 class="mb-3">Professores selecionados</h4>
                <div
                  v-for="(mentor, i) in stats.mentorsWithOpenConversations"
                  :key="i"
                  class="d-inline-flex"
                >
                  <p class="badge badge-primary mr-1 mb-1"
                  style="font-size: 16px !important;"
                  >{{`${mentor.name} ${mentor.surname}`}}</p>
                </div>
              </div>
            </div>
          </template>

          <div
            v-if="
              user.profession ||
              user.bio ||
              (showInterests && showInterests.length)
            "
            class="card"
          >
            <div class="card-body p-4">
              <template v-if="user.profession">
                <h4 class="mb-3">
                  Profissão:
                  <span class="badge badge-dark p-2 mx-1">{{
                    user.profession
                  }}</span>
                </h4>
              </template>

              <template v-if="user.bio">
                <h4 class="mb-1">Biografia:</h4>
                <div v-html="user.bio"></div>
              </template>

              <template v-if="showInterests && showInterests.length">
                <h5 class="mt-2">Meus interesses:</h5>
                <div
                  v-for="(interest, i) in showInterests"
                  :key="i"
                  class="d-inline-flex"
                >
                  <span
                    class="badge badge-info mr-1 mb-1"
                    style="font-size: 16px !important;"
                  >
                    {{ interest }}
                  </span>
                </div>
              </template>
            </div>
          </div>

          <div v-if="stats && !user.hideStats" class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">Questões no dia</h4>
                  <div class="row justify-content-center">
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.daily.totalAnswers }}
                        </h5>
                        <p class="text-muted">Resolvidas</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.daily.correctAnswers }}
                        </h5>
                        <p class="text-muted">Acertos</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{
                            stats.answers.daily.totalAnswers -
                            stats.answers.daily.correctAnswers
                          }}
                        </h5>
                        <p class="text-muted">Erros</p>
                      </div>
                    </div>
                  </div>

                  <apexchart
                    class="apex-charts"
                    height="320"
                    type="pie"
                    :series="[
                      stats.answers.daily.correctAnswers,
                      stats.answers.daily.totalAnswers -
                        stats.answers.daily.correctAnswers,
                    ]"
                    :options="{
                      labels: ['Acertos', 'Erros'],
                      colors: ['#02a499', '#ec4561'],
                      legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        verticalAlign: 'middle',
                        floating: false,
                        fontSize: '14px',
                        offsetX: 0,
                        offsetY: -5,
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      responsive: [
                        {
                          breakpoint: 600,
                          options: {
                            chart: {
                              height: 250,
                            },
                            legend: {
                              show: false,
                            },
                          },
                        },
                      ],
                    }"
                  ></apexchart>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">Questões na semana</h4>
                  <div class="row justify-content-center">
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.weekly.totalAnswers }}
                        </h5>
                        <p class="text-muted">Resolvidas</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.weekly.correctAnswers }}
                        </h5>
                        <p class="text-muted">Acertos</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{
                            stats.answers.weekly.totalAnswers -
                            stats.answers.weekly.correctAnswers
                          }}
                        </h5>
                        <p class="text-muted">Erros</p>
                      </div>
                    </div>
                  </div>

                  <apexchart
                    class="apex-charts"
                    height="320"
                    type="pie"
                    :series="[
                      stats.answers.weekly.correctAnswers,
                      stats.answers.weekly.totalAnswers -
                        stats.answers.weekly.correctAnswers,
                    ]"
                    :options="{
                      labels: ['Acertos', 'Erros'],
                      colors: ['#02a499', '#ec4561'],
                      legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        verticalAlign: 'middle',
                        floating: false,
                        fontSize: '14px',
                        offsetX: 0,
                        offsetY: -5,
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      responsive: [
                        {
                          breakpoint: 600,
                          options: {
                            chart: {
                              height: 250,
                            },
                            legend: {
                              show: false,
                            },
                          },
                        },
                      ],
                    }"
                  ></apexchart>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">Questões no mês</h4>
                  <div class="row justify-content-center">
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.monthly.totalAnswers }}
                        </h5>
                        <p class="text-muted">Resolvidas</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.monthly.correctAnswers }}
                        </h5>
                        <p class="text-muted">Acertos</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{
                            stats.answers.monthly.totalAnswers -
                            stats.answers.monthly.correctAnswers
                          }}
                        </h5>
                        <p class="text-muted">Erros</p>
                      </div>
                    </div>
                  </div>

                  <apexchart
                    class="apex-charts"
                    height="320"
                    type="pie"
                    :series="[
                      stats.answers.monthly.correctAnswers,
                      stats.answers.monthly.totalAnswers -
                        stats.answers.monthly.correctAnswers,
                    ]"
                    :options="{
                      labels: ['Acertos', 'Erros'],
                      colors: ['#02a499', '#ec4561'],
                      legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        verticalAlign: 'middle',
                        floating: false,
                        fontSize: '14px',
                        offsetX: 0,
                        offsetY: -5,
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      responsive: [
                        {
                          breakpoint: 600,
                          options: {
                            chart: {
                              height: 250,
                            },
                            legend: {
                              show: false,
                            },
                          },
                        },
                      ],
                    }"
                  ></apexchart>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">Total de questões</h4>
                  <div class="row justify-content-center">
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.ever.totalAnswers }}
                        </h5>
                        <p class="text-muted">Resolvidas</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{ stats.answers.ever.correctAnswers }}
                        </h5>
                        <p class="text-muted">Acertos</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="text-center">
                        <h5 class="mb-0 font-size-20">
                          {{
                            stats.answers.ever.totalAnswers -
                            stats.answers.ever.correctAnswers
                          }}
                        </h5>
                        <p class="text-muted">Erros</p>
                      </div>
                    </div>
                  </div>

                  <apexchart
                    class="apex-charts"
                    height="320"
                    type="pie"
                    :series="[
                      stats.answers.ever.correctAnswers,
                      stats.answers.ever.totalAnswers -
                        stats.answers.ever.correctAnswers,
                    ]"
                    :options="{
                      labels: ['Acertos', 'Erros'],
                      colors: ['#02a499', '#ec4561'],
                      legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        verticalAlign: 'middle',
                        floating: false,
                        fontSize: '14px',
                        offsetX: 0,
                        offsetY: -5,
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      responsive: [
                        {
                          breakpoint: 600,
                          options: {
                            chart: {
                              height: 250,
                            },
                            legend: {
                              show: false,
                            },
                          },
                        },
                      ],
                    }"
                  ></apexchart>
                </div>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">
                    Comparação com os demais membros
                  </h4>

                  <strong>Legenda:</strong> <br />
                  <b-badge
                    variant="success"
                    class="mr-2"
                    style="background-color: #f0fdf4; color: black;"
                  >
                    Zona de normalidade
                  </b-badge>

                  <apexchart
                    class="apex-charts"
                    dir="ltr"
                    height="380"
                    type="line"
                    :series="[
                      {
                        name: ' ',
                        data: stats.usersByMean.map((i) => ({
                          y: i.count,
                          x: i.mean,
                        })),
                      },
                    ]"
                    :options="{
                      chart: {
                        zoom: {
                          type: 'x',
                          enabled: true,
                          autoScaleYaxis: true,
                        },
                        toolbar: {
                          show: false,
                        },
                      },
                      toolbar: {
                        autoSelected: 'zoom',
                      },
                      colors: ['#626ed4'],
                      dataLabels: {
                        enabled: true,
                      },
                      stroke: {
                        width: [3, 3],
                        curve: 'smooth',
                      },
                      title: {
                        align: 'left',
                        style: {
                          fontSize: '14px',
                          color: '#666',
                        },
                      },
                      grid: {
                        row: {
                          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                          opacity: 0.2,
                        },
                        borderColor: '#f1f3fa',
                      },
                      markers: {
                        style: 'inverted',
                        size: 6,
                      },
                      xaxis: {
                        title: {
                          text: 'Total de acertos',
                        },
                      },
                      yaxis: {
                        title: {
                          text: 'Usuários',
                        },
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5,
                      },
                      annotations: {
                        xaxis: [
                          {
                            x: stats.globals.mean,
                            strokeDashArray: 0,
                            borderColor: '#775DD0',
                            label: {
                              borderColor: '#775DD0',
                              style: {
                                fill: '#ffffff',
                                background: 'rgba(0, 132, 255, 1)',
                              },
                              text: 'Média global',
                            },
                          },
                          {
                            x: stats.answers.ever.correctAnswers,
                            strokeDashArray: 0,
                            borderColor: '#343a40',
                            label: {
                              borderColor: '#343a40',
                              style: {
                                color: '#fff',
                                background: '#343a40',
                              },
                              text: 'Meus acertos',
                            },
                          },
                          {
                            x:
                              stats.globals.mean -
                              Math.abs(stats.globals.standardDeviation),
                            x2:
                              stats.globals.mean +
                              Math.abs(stats.globals.standardDeviation),
                            fillColor: '#B3F7CA',
                            opacity: 0.2,
                            label: {
                              borderColor: '#B3F7CA',
                              style: {
                                fontSize: '10px',
                                color: '#fff',
                                background: '#00E396',
                              },
                              offsetY: -10,
                            },
                          },
                        ],
                      },
                    }"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>
<script>
import * as api from "@/api";
import Layout from "../layouts/main";
import { mapState } from "vuex";
import UserVerticalCard from "@/components/UserVerticalCard.vue";
import UserBadges from "@/components/UserBadges.vue";

export default {
  name: "Profile",

  components: { Layout, UserVerticalCard, UserBadges },

  computed: {
    ...mapState("auth", ["currentUser"]),

    showInterests() {
      let interests = this.user.interests.map((interest) => interest.name);
      return interests;
    },
  },

  data() {
    return {
      loading: true,
      user: false,
      stats: false,
    };
  },

  created() {
    api
      .getUserByUsername(this.$route.params.username, { addBadges: true })
      .then((user) => {
        return api.getUserStatsById(user.id).then((stats) => {
          this.user = user;
          this.loading = false;
          this.stats = stats;
        });
      })
      .catch(() => {
        this.$swal(
          "Este usuário não existe",
          "Verifique o nome de usuário",
          "error"
        );
        this.loading = false;
      });
  },

  methods: {
    // showInterests(user) {
    //   let interests = user.interests.map((interest) => interest.name);
    //   return interests.join(", ");
    // },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body p-4">
      <div class="image-box mx-3 mt-2 mb-4">
        <b-img
          :src="
            user.avatar
              ? $getImageUrl(user.avatar.originalName)
              : $defaultUserAvatar
          "
          rounded
          class="img-fluid rounded-circle mx-auto d-block"
          width="250"
          :alt="user.name"
          style="width: 100%; height: auto; max-width: 256px;"
        >
        </b-img>
        <div
          v-if="isEditMode"
          id="user-vertical-cart-avatar"
          class="overlay-info"
        >
          <div class="content-info">
            <i class="mdi mdi-camera"></i>
          </div>
        </div>
      </div>
      <div class="text-center mx-3 my-4">
        <h3 class="mb-3">{{ user.name }} {{ user.surname }}</h3>
        <div
          v-if="currentUser.id === user.id && !isEditMode"
          class="mx-auto d-block mb-3"
        >
          <router-link
            :to="{ name: 'editProfile' }"
            class="btn btn-info rounded-pill"
          >
            <i class="mdi mdi-pencil-outline"></i>
            Editar Perfil
          </router-link>
        </div>

        <template v-if="currentUser.role === 'admin'">
          <b-button
            v-if="user.mentorLevel !== 1"
            pill
            variant="primary"
            @click="toggleMentorLevel(1)"
            class="mr-2 mb-2"
          >
            Tornar um anjo
          </b-button>
          <b-button
            v-if="user.mentorLevel > 0"
            pill
            variant="danger"
            @click="toggleMentorLevel(0)"
            class="mr-2 mb-2"
          >
            Remover cargo de {{ user.mentorLevel === 1 ? "anjo" : "professor" }}
          </b-button>
          <b-button
            v-if="user.mentorLevel !== 2"
            pill
            variant="primary"
            @click="toggleMentorLevel(2)"
            class="mr-2 mb-2"
          >
            Tornar um professor
          </b-button>
        </template>
      </div>
      <hr />

      <template v-if="currentUser.role === 'admin'">
        <div class="text-center mx-3 my-4">
          <b-button-group size="sm">
            <b-button
              v-for="role in [
                { value: 'member', label: 'membro', variant: 'info' },
                { value: 'reviewer', label: 'revisador', variant: 'success' },
                {
                  value: 'content-manager',
                  label: 'gerente',
                  variant: 'warning',
                },
                {
                  value: 'admin',
                  label: 'admin',
                  variant: 'secondary',
                },
              ].filter((role) => role.value !== user.role)"
              :key="role.value"
              :variant="role.variant"
              @click="setRole(role.value)"
            >
              Tornar um {{ role.label }}
            </b-button>
          </b-button-group>
        </div>
        <hr />
      </template>

      <div class="m-2">
        <h4 class="my-3">Informações</h4>

        <template v-if="user.mentorLevel > 0">
          <h5 class="mt-3 text-info">
            <i class="fas fa-chalkboard-teacher"></i>
            Usuário {{ user.mentorLevel === 2 ? "Professor" : "Anjo" }}
          </h5>
          <h6>
            Este usuário está apto para ajudar outros
          </h6>
        </template>

        <template v-if="user.email">
          <h5 class="mt-3">E-mail</h5>
          <h6 class="text-primary">{{ user.email }}</h6>
        </template>

        <template v-if="user.cashback">
          <h5 class="mt-3">Remuneração Acumulada</h5>
          <h6 class="text-primary">{{ user.cashback.amount }}</h6>
          <button href="" @click="resgatar()" class="btn btn-primary btn-sm" v-if="user.cashback.amount > 300">
            <i class="mdi mdi-coin-outline"></i>
            Contate contato@questoepro.com.br com seus dados bancários e o valor desejado.
          </button>
          <span class="text-muted" v-else>
            O cashback só pode ser solicitado quando sua remuneração atingir 300 moedas.
          </span>
        </template>

        <template v-if="user.birthDate">
          <h5 class="mt-3">Data de nascimento</h5>
          <h6 class="text-primary">
            {{ user.birthDate.split("-").reverse().join("/") }}
          </h6>
        </template>

        <template v-if="user.telephone">
          <h5 class="mt-3">Telefone</h5>
          <h6 class="text-primary">{{ user.telephone }}</h6>
        </template>

        <div class="my-4">
          <a
            v-if="user.facebook"
            :href="`https://www.facebook.com/${user.facebook}`"
            target="_blank"
            class="btn btn-outline-dark mx-1"
          >
            <i class="mdi mdi-facebook-box mdi-24px"></i>
          </a>
          <a
            v-if="user.instagram"
            :href="`https://www.instagram.com/${user.instagram}`"
            target="_blank"
            class="btn btn-outline-dark mx-1"
          >
            <i class="mdi mdi-instagram mdi-24px"></i>
          </a>
          <a
            v-if="user.twitter"
            :href="`https://www.twitter.com/${user.twitter}`"
            target="_blank"
            class="btn btn-outline-dark mx-1"
          >
            <i class="mdi mdi-twitter mdi-24px"></i>
          </a>
          <a
            v-if="user.linkedin"
            :href="`https://www.linkedin.com/${user.linkedin}`"
            target="_blank"
            class="btn btn-outline-dark mx-1"
          >
            <i class="mdi mdi-linkedin mdi-24px"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { mapState } from "vuex";

export default {
  name: "UserVerticalCard",

  props: {
    user: {
      type: Object,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  methods: {
    setRole(role) {
      return api
        .updateUser(this.user.id, { role })
        .then((updated) => {
          this.user.role = updated.role;
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado", "error");
        });
    },

    toggleMentorLevel(mentorLevel) {
      return api
        .updateUser(this.user.id, { mentorLevel })
        .then((updated) => {
          this.user.mentorLevel = updated.mentorLevel;
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado", "error");
        });
    },
  },
};
</script>

<style lang="scss">
.image-box {
  position: relative;
  min-width: 256px;
  min-height: 256px;
  max-width: 100%;
  height: auto;

  .overlay-info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    border-radius: 50% !important;
    background-color: rgba(12, 12, 12, 0.25);
  }

  .content-info {
    color: #ffffff;
    font-size: 72px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &:hover {
    .overlay-info {
      opacity: 1;
    }
  }
}
</style>
